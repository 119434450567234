<template>
  <div>
    <template v-for="(item, index) in options">
      <template v-if="values.includes(item.value)">
        <span
          v-if="item.raw.listClass == 'default' || item.raw.listClass == ''"
          :key="item.value"
          :index="index"
          :class="item.raw.cssClass"
        >
          {{ item.label }}
        </span>
        <a-badge
          v-else
          :key="item.value"
          :index="index"
          :status="item.raw.listClass|statusFilter"
          :class="item.raw.cssClass"
          :text="item.label"
        >
        </a-badge>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'DictTag',
  props: {
    options: {
      type: Array,
      default: null
    },
    value: {
      type: [Number, String, Array],
      default: null
    }
  },
  computed: {
    values () {
      console.log(this.options,this.value)
      if (this.value !== null && typeof this.value !== 'undefined') {
        return Array.isArray(this.value) ? this.value : [String(this.value)]
      } else {
        return []
      }
    }
  },
  filters: {
    statusFilter(lclass) {
      let str = ''
      lclass == 'primary' || lclass == 'info' ? str = 'processing' :
      lclass == 'danger' ? str = 'error' : str = lclass
      return str
    }
  }
}
</script>
