<template>
  <div :class='wrpCls'>

    <div class='header_right'>
      <div>账户余额：{{ balance }}</div>

      <a-button type='primary' :href="modeType=='development'?'http://localhost':'https://acc-admin.shiwei.love'"
                target='_blank' class='recharge'>充值
      </a-button>
      <a-space size='middle'>
        <screenfull />
        <notice-icon v-hasPermi="['system:notice:list']" style='cursor: pointer;' />
        <avatar-dropdown :menu='showMenu' :current-user='currentUser' :class='prefixCls' />
        <!--      <select-lang :class="prefixCls" />-->
      </a-space>
    </div>
  </div>
</template>

<script>
import AvatarDropdown from './AvatarDropdown'
import NoticeIcon from '@/components/NoticeIcon'
import Screenfull from '@/components/Screenfull'
import SelectLang from '@/components/SelectLang'
import { getAccountBalance } from '@/api/system/user'

export default {
  name: 'RightContent',
  components: {
    AvatarDropdown,
    NoticeIcon,
    Screenfull,
    SelectLang
  },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-global-header-index-action'
    },
    isMobile: {
      type: Boolean,
      default: () => false
    },
    topMenu: {
      type: Boolean,
      required: true
    },
    theme: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      modeType: process.env.NODE_ENV,
      balance: 0,
      showMenu: true,
      currentUser: {},
      docUrl: process.env.VUE_APP_URL_AGREEMENT + process.env.VUE_APP_URL_HOST + process.env.VUE_APP_BASE_API + '/doc',
      githubUrl: 'http://gitlab.niurenjianzhan.com/zhangxun/java-framework'
    }
  },
  computed: {
    wrpCls() {
      return {
        'ant-pro-global-header-index-right': true,
        [`ant-pro-global-header-index-${(this.isMobile || !this.topMenu) ? 'light' : this.theme}`]: true
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.currentUser = {
        name: 'RuoYi'
      }
    }, 1500)
    getAccountBalance().then(res => {
        this.balance = res.data
        console.log(res, 'getAccountBalance')
      }
    )
  },
  methods: {
    toDoc() {
      window.open(this.docUrl)
    }
    ,
    toGithub() {
      window.open(this.githubUrl)
    }
  }
}
</script>
<style scoped>
.header_right {
  display: flex;
  align-items: center;
}

.recharge {
  margin-left: 20px;
}

</style>